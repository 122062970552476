import './App.css';
import { Amplify, API, Analytics } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

//////////////Load Image Start//////////////
import imgwhatsapp from './img/whatsapp.png';
import imgChangeCamera from './img/changeCamera.png';
import imgWebsiteQRCode from './img/websiteQRCode.png';
import imgWelcomePageBG from './img/WelcomePageBG.jpg';
import imgWelcomePageStep1 from './img/WelcomePageStep1.png';
import imgWelcomePageStep2 from './img/WelcomePageStep2.png';
import imgWelcomePageStep3 from './img/WelcomePageStep3.png';
import imgMenuInfo from './img/MenuInfo.png';
import imgMenuHome from './img/MenuHome.png';
import imgMenuScan from './img/MenuScan.png';
import imgscanBorder from './img/scanBorder.png';
import WeChatQRCode from "./img/WeChatQRCode.jpg";
//////////////Load Image End //////////////

import {
  BrowserQRCodeReader
} from "@zxing/library";
import { useEffect, useState,useRef } from "react";
import Modal from "react-modal";

import './i18n';
import { Link, Route, useLocation, useHistory } from "react-router-dom";
import Instructions from './instructions'
import { useTranslation } from 'react-i18next';
import Loadingspin from "./loadingspin";
import { isMobile } from 'react-device-detect';
import './config';
import QrScanner from 'qr-scanner';
QrScanner.WORKER_PATH = 'qr-scanner-worker.min.js';
Amplify.configure(awsconfig);

const apiName = 'qrcodevalidationapi';

function App() {
  console.log("Hello React");
  const [cookies, setCookie] = useCookies(['DID']);
  const { t, i18n } = useTranslation();
  const [isScan, setIsScan] = useState(false);
  const [isWelcomePage, setIsWelcomePage] = useState(true);
  const [isResult, setIsResult] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isProd, setIsProd] = useState(global.config.isProduction);

  const [resultText, setresultText] = useState(t('ResultPage.ValidResultText'));
  const [resultText2, setresultText2] = useState("");
  const [resultScanTime, setresultScanTime] = useState("");
  const [resultText3, setresultText3] = useState("");
  const [resultAlertText, setresultAlertText] = useState(t('ResultPage.AlertResultMsg1'));
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isShowResultText, setIsShowResultText] = useState(true);
  const [isResultValid, setIsResultValid] = useState(true);
  const [isOverScan, setIsOverScan] = useState(false);
  const [isPreviousEvent, setIsPreviousEvent] = useState(false);
  const [eventID, setEventID] = useState("1");
  const [resultimg, setresultimg] = useState(t('ResultPage.ResultPageHeaderOK_Default_img'));
  const [isShowCopyButton, setIsShowCopyButton] = useState(false);
  const [whatsappURLWithQRCode, setwhatsappURLWithQRCode] = useState("");
  const [latitude, setlatitude] = useState(0.0);
  const [longitude, setlongitude] = useState(0.0);
  const [isIphone, setIsIphone] = useState(true);

  var codeReader = new BrowserQRCodeReader();
  var isOnScanPage = false;
  var whatsappURL = "https://wa.me/85291628866?text=";
  var camName = "";
  var _DID = "";
  var qrScanner;
  //setIsProd(global.config.isProduction);
  ///////////////////////////////////////


  async function SetDID()
  {
    if(!cookies.DID || cookies.DID=="")
    {
      let d = new Date();
      d.setTime(d.getTime() + (10*365*24*60*60*1000));
      //let CurrentDateTime = new Date();
      
	    let date_ob = new Date();
      let date = ("0" + date_ob.getDate()).slice(-2);
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let year = date_ob.getFullYear();
      let hours = ("0" + date_ob.getHours()).slice(-2);
      let minutes =("0" + date_ob.getMinutes()).slice(-2);
      let seconds =("0" + date_ob.getSeconds()).slice(-2);
      let ms = ("00" +date_ob.getMilliseconds()).slice(-3);
      if (_DID == "")
      {
        _DID = uuidv4()+"_"+year+month+date+"_"+hours+minutes+seconds+"_"+ms;
        setCookie('DID', _DID, { path: '/' , expires: d});
      }
    }
    else
    {
      _DID = cookies.DID;
    }
  }

/*
  if(!cookies.DID || cookies.DID=="")
  {
    await SetDID();
  }
  else
  {
    _DID = cookies.DID;
  }
  */ 
  //Welcome page start

  function Step1onClick() {
    
    setIsWelcomePage(false);
    var btninstructions = document.getElementById('btninstructions');
    btninstructions.click();

  }
  function Step2imgonClick(e) {
    setIsWelcomePage(false);
    var btninstructions = document.getElementById('btninstructions2');
    btninstructions.click();
  }
  function Step2onClick(e) {
    setIsWelcomePage(false);
    var btninstructions = document.getElementById('btninstructions2');
    
    var img=document.getElementById("imgWelcomePageStep2");
    var imgx = e.clientX - img.offsetLeft + window.scrollX - document.getElementById("WelcomePageMain").offsetLeft - document.getElementById("WelcomePageStep2Border2").offsetLeft;
    var imgy = e.clientY - img.offsetTop + window.scrollY-document.getElementById("WelcomePageStep1Border1").offsetTop - document.getElementById("WelcomePageStep1Border2").offsetTop - document.getElementById("WelcomePageStep2Border2").offsetTop-60;

    if (img.height*0.3 > imgy && img.width*0.3>imgx)
    {
      var btninstructions2 = document.getElementById('btninstructions');
      btninstructions2.click();
    }
    else{
      btninstructions.click();
    }
    
  }
  function Step3onClick(e) {
    var btninstructions3 = document.getElementById('btninstructions3');
    var img=document.getElementById("imgWelcomePageStep3");
    var imgx = e.clientX - img.offsetLeft + window.scrollX - document.getElementById("WelcomePageMain").offsetLeft - document.getElementById("WelcomePageStep3Border2").offsetLeft;
    var imgy = e.clientY - img.offsetTop + window.scrollY-document.getElementById("WelcomePageStep1Border1").offsetTop - document.getElementById("WelcomePageStep1Border2").offsetTop - document.getElementById("WelcomePageStep3Border2").offsetTop-60;

    if (img.height*0.3 > imgy && img.width*0.3>imgx)
    {
      var btninstructions = document.getElementById('btninstructions2');
      btninstructions.click();
    }
    else{
      btninstructions3.click();
    }

  }

  ////////////////////////////
  //get url parameter to go scan page
  var IsTriggerIsScan = false;

  const history = useHistory()
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  if (query.get("isScan") === '1' && !IsTriggerIsScan) {

    history.replace({
      isScan: 0,
    })
    setTimeout(() => {
      //controls.stop();
      if (!IsTriggerIsScan) {
        IsTriggerIsScan = true;
        window.Html5Qrcode.getCameras().then(devices => {
          /**
           * devices would be an array of objects of type:
           * { id: "id", label: "label" }
           */
          if (devices && devices.length) {
            //cameraId = devices[cameraIndex].id;
            // .. use this to start scanning.
          }
        }).catch(err => {
          // handle err
        });
        setIsWelcomePage(false);
        setIsScan(true);
        //start();

        setTimeout(() => {
          //start();
          scanCode();
        }, 1000);
      }
    }, 1000);
  }
  
    /*
  useEffect(() => { 
    

    //preloading image
    var imageList = [imgResultPageHeader1, imgResultPageHeader2]
    imageList.forEach((image) => {
      const img = new Image();
      img.src = image;
    });

    var img = new Image();
    img.src = "./img/TW/ResultPageHeader1.jpg";
    img = new Image();
    img.src = "./img/TW/ResultPageHeader2.jpg";
    img = new Image();
    img.src = "./img/CN/ResultPageHeader1.jpg";
    img = new Image();
    img.src = "./img/CN/ResultPageHeader2.jpg";
  }, []);
    */
  useEffect(() => {
    
    document.title = t('WebsiteTitle');
    return history.listen(location => {
      // && history.location.pathname === "any specific path")
      if (history.action === 'PUSH') {
        
      }
      if (history.action === "POP") {
        if (location.pathname === "/")
        {
          MenuBackToHome();
        }
      }
    })
  }, [history])
  ////////////////////////////
  //Welcome page End
  ///////////////////////////////////////

  ///////////////////////////////////////
  //scan page start
  
  var cameraIndex = 0;
  var isChangeCamera = false;
  var cameraId;
  const [isShowScanBorder, setIsShowScanBorder] = useState(false);
  
  const [cameraInfo, setCameraInfo] = useState("");
  const [camindex, setcamindex] = useState(0);
  const [isMultiCam, setIsMultiCam] = useState(false);

  async function scanCode() {
    
    if (global.config.isChangeCamera)
    {
      startBackCamera(global.config.cameraId);
    }
    else
    {
      const videoInputDevices = await codeReader.listVideoInputDevices();
      if (videoInputDevices && videoInputDevices.length) {

        var backCam = videoInputDevices.filter(d => {
          return d.label && !d.label.includes("front") && !d.label.includes("前置") && !d.label.includes("前置");
        });

        if (backCam.length>1)
        {
          setIsMultiCam(true);
        }
      }
      window.Html5Qrcode.getCameras().then(devices => {
        var backCam = devices.filter(d => {
          return d.label && d.label === "camera2 0, facing back";
        });
        if (backCam.length > 0) {
          startBackCamera(backCam[0].id);
        }
        else {
          startBackCamera(undefined);
        }

      }).catch(err => {
        // handle err
      });
    }
  }
  
  async function startBackCamera(camid) {
    let isIOS = /iPad|iPhone|iPod/.test(navigator.platform)  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    if (isIOS)
    {
      setIsIphone(true);
      startBackCameraIphone();
    }
    else 
    {
      setIsIphone(false);
      startBackCameraAndroid(camid);
    }
  }
  async function startBackCameraIphone() {
    try {
      document.body.style.background = '#E8D9C4';
      Analytics.record({ name: 'StartCamera' });
      global.config.CameraStart=true;
      navigator.geolocation.getCurrentPosition(function(position) {
        setlatitude(position.coords.latitude);
        setlongitude(position.coords.longitude);
      });
      setIsWelcomePage(false);
      setIsScan(true);
      isOnScanPage = true;
      //setDisplay("block");

      if (global.config.isChangeCamera)
      {
        //camertemp=global.config.cameraId;
      }
    const previewElem = document.getElementById("qrcodevideo");
       qrScanner = new QrScanner(previewElem, result =>{ 
              if (result != "")
              {
                qrScanner.stop();
                qrScanner.$canvas.style.display = 'none';
                //alert('decoded qr code:'+ result);
                global.config.CameraStart=false;
                const canvas = document.createElement("canvas");
                // scale the canvas accordingly
                canvas.width = previewElem.videoWidth;
                canvas.height = previewElem.videoHeight;
                // draw the video at that frame
                canvas.getContext('2d')
                  .drawImage(previewElem, 0, 0, canvas.width, canvas.height);
                const dataURL = canvas.toDataURL();
                var base64Image=canvas.toDataURL('image/jpeg',0.7);
                try
                {
                  let base64Length = base64Image.length - (base64Image.indexOf(',') + 1);
                  let padding = (base64Image.charAt(base64Image.length - 2) === '=') ? 2 : ((base64Image.charAt(base64Image.length - 1) === '=') ? 1 : 0);
                  let fileSize = base64Length * 0.75 - padding;
                  if (fileSize > 65000)
                  {
                      base64Image=canvas.toDataURL('image/jpeg',0.5);
                      base64Length = base64Image.length - (base64Image.indexOf(',') + 1);
                      padding = (base64Image.charAt(base64Image.length - 2) === '=') ? 2 : ((base64Image.charAt(base64Image.length - 1) === '=') ? 1 : 0);
                      fileSize = base64Length * 0.75 - padding;
                      if (fileSize > 65000)
                      {
                          base64Image=canvas.toDataURL('image/jpeg',0.3);
                      }
                  }
                }
                catch(e)
                {
                  alert(e);
                }

                isOnScanPage = false;
                setIsScan(false);
                setIsShowLoading(true);
                setwhatsappURLWithQRCode(whatsappURL + encodeURIComponent(t('ResultPage.QRCodeContent'))+ "%0A" + encodeURIComponent(result)+ "%0A" + encodeURIComponent(t('ResultPage.QRCodeContent2')));
                PostQRCode(result,base64Image);
                setIsShowScanBorder(false);
                codeReader.reset();
              }
            },err =>{
              if(!global.config.CameraStart){
                qrScanner.stop();
                qrScanner.$canvas.style.display = 'none';
              }
            },calculateScanRegion,undefined);
      

      //qrScanner.setCamera(camid);
      
      qrScanner.start();
      
      previewElem.parentNode.parentNode.insertBefore(qrScanner.$canvas, previewElem.parentNode);
      qrScanner.$canvas.style.display = 'block';
      qrScanner.$canvas.style.width="100%";
      qrScanner.$canvas.id = 'qrScannercanvas';

      
      if (!isShowScanBorder)
      {
        try{
          var CamBorderHeight = window.innerHeight-document.getElementById("qr-code-webcam-iphone").clientHeight-120;
          var CamHeight = document.getElementById("qr-code-webcam-iphone").clientHeight;
          var imgscanBorderHeight = 0;
          try{
            imgscanBorderHeight = document.getElementById("imgscanBorder").clientHeight;
          }  
          catch(ex)
          {
      
          }
          setimgscanBorderTopx(Math.round((window.innerHeight/10))+'px');

        }  
        catch(ex)
        {
    
        }
      }
      setIsShowScanBorder(true);
      
    } catch (error) {
      console.log(error);
    }
  }
  function  calculateScanRegion() {
    var video = document.getElementById("qrcodevideo");
    var height=window.innerHeight;
    if (window.innerWidth>height)
    {
      height=window.innerWidth;
    }
    return {
      x: video.videoWidth/4,
      y: video.videoHeight/4,
      width: document.getElementById("qr-code-webcam-iphone").clientWidth/2,//video.videoWidth,
      height: height/2,//video.videoHeight,
      downScaledWidth: document.getElementById("qr-code-webcam-iphone").clientWidth,//video.videoWidth,
      downScaledHeight: height,//window.innerHeight,//video.videoHeight,
    };
}
  async function startBackCameraAndroid(camid) {
    try {
      document.body.style.background = '#E8D9C4';
      Analytics.record({ name: 'StartCamera' });
      global.config.CameraStart=true;
      navigator.geolocation.getCurrentPosition(function(position) {
        setlatitude(position.coords.latitude);
        setlongitude(position.coords.longitude);
      });
      setIsWelcomePage(false);
      setIsScan(true);
      isOnScanPage = true;
      //setDisplay("block");
      const previewElem = document.querySelector(
        "#qr-code-webcam > video"
      );

      var camertemp=camid;
      if (global.config.isChangeCamera)
      {
        //camertemp=global.config.cameraId;
      }
      const controls = await codeReader.decodeFromVideoDevice(
        //cameraid,
        camertemp,
        previewElem,
        (result, error, controls) => {
          if (error != "NotFoundException" && result !== null && result !== '') {

            const canvas = document.createElement("canvas");
            // scale the canvas accordingly
            canvas.width = previewElem.videoWidth;
            canvas.height = previewElem.videoHeight;
            // draw the video at that frame
            canvas.getContext('2d')
              .drawImage(previewElem, 0, 0, canvas.width, canvas.height);
            // convert it to a usable data URL
            const dataURL = canvas.toDataURL();
            //const a = document.createElement('a');
            //a.download = 'download2.jpg';
            //a.href = canvas.toDataURL('image/jpeg',0.7);
            //a.click();
            var base64Image=canvas.toDataURL('image/jpeg',0.7);
            //alert(base64Image.length);
            try
            {
              let base64Length = base64Image.length - (base64Image.indexOf(',') + 1);
              let padding = (base64Image.charAt(base64Image.length - 2) === '=') ? 2 : ((base64Image.charAt(base64Image.length - 1) === '=') ? 1 : 0);
              let fileSize = base64Length * 0.75 - padding;
              if (fileSize > 65000)
              {
                  base64Image=canvas.toDataURL('image/jpeg',0.5);
                  base64Length = base64Image.length - (base64Image.indexOf(',') + 1);
                  padding = (base64Image.charAt(base64Image.length - 2) === '=') ? 2 : ((base64Image.charAt(base64Image.length - 1) === '=') ? 1 : 0);
                  fileSize = base64Length * 0.75 - padding;
                  if (fileSize > 65000)
                  {
                      base64Image=canvas.toDataURL('image/jpeg',0.3);
                  }
              }
            }
            catch(e)
            {
              alert(e);
            }
            //var base64Imageorg="";//canvas.toDataURL('image/jpeg',0.3);

            isOnScanPage = false;
            setIsScan(false);
            setIsShowLoading(true);
            setwhatsappURLWithQRCode(whatsappURL + encodeURIComponent(t('ResultPage.QRCodeContent'))+ "%0A" + encodeURIComponent(result.text)+ "%0A" + encodeURIComponent(t('ResultPage.QRCodeContent2')));
            PostQRCode(result.text,base64Image);
            setIsShowScanBorder(false);
            codeReader.reset();
          }
          else if (!global.config.CameraStart){
            codeReader.reset();
            setIsShowScanBorder(false);
            setIsScan(false);
          }
          else
          {
            var scandiv = document.getElementById("qr-code-webcam");
            if (scandiv == null)
            {
              codeReader.reset();
              setIsShowScanBorder(false);
              if (isMobile)
                setIsScan(false);
            }
            else
            {
              if (!isShowScanBorder)
              {
                try{
                  var CamBorderHeight = window.innerHeight-document.getElementById("qr-code-webcam").clientHeight-120;
                  var CamHeight = document.getElementById("qr-code-webcam").clientHeight;
                  var imgscanBorderHeight = document.getElementById("imgscanBorder").clientHeight;
                  
                  if (CamHeight<CamBorderHeight)
                  {
                    setimgscanBorderTopx(Math.round((CamHeight-imgscanBorderHeight)/2)+'px');
                  }
                  else
                  {
                    setimgscanBorderTopx(Math.round((CamHeight-imgscanBorderHeight)/2)+'px');
                  }
                }  
                catch(ex)
                {
            
                }
              }
              setIsShowScanBorder(true);
            }
          }
        }
      );

    } catch (error) {
      console.log(error);
    }
  }
  
  //get gps location when init
  navigator.geolocation.getCurrentPosition(function (position) {
    setlatitude(position.coords.latitude);
    setlongitude(position.coords.longitude);
  });


  async function PostQRCode(QRCodeContent,base64Image) {
    if(!_DID || _DID=="" )
    {
      await SetDID();
    }
    const myInit = { // OPTIONAL
      body: {
        content: QRCodeContent,
        camName: camName,
        camIndex: camindex,
        latitude: latitude,
        longitude: longitude,
        did: _DID,
        base64Image: base64Image
      }, 
      headers: {}, // OPTIONAL
    };
    setresultText3(QRCodeContent);
    API.post(apiName, '/qrcode', myInit).then(res => {
      try {
        console.log(API.endpoints);
        setIsShowAlert(false);
        setIsPreviousEvent(false);
        setIsShowResultText(true);
        if (res.isValid) {
          var validateCountstr = parseInt(res.validateCount) + 1;
          setresultText(t('ResultPage.ValidResultText'));
          setresultText2(validateCountstr);
          if (validateCountstr>1)
            setresultScanTime(res.firstScanTime);
          setIsOverScan(false);
          if (validateCountstr > 5)
          {
            //setIsOverScan(true);
          }
          setresultimg(t('ResultPage.ResultPageHeaderOK_Default_img'));
          setIsShowCopyButton(true);
          setIsResultValid(true);
          if (res.IsAlert)
          {
            
            //alert(t('ResultPage.AlertPopupMsg'));
            setIsShowAlert(true);
            setIsShowResultText(false);
            setresultAlertText(t('ResultPage.AlertResultMsg1'));
            setresultimg(t('ResultPage.ResultPageHeaderIssue_img'));
          } else if (res.eventId === "5") {
            setIsPreviousEvent(false);
            setresultimg(t('ResultPage.ResultPageHeaderOK_2024_01_img'));
          } else if (res.eventId === "6") {
            setIsPreviousEvent(false);
            setresultimg(t('ResultPage.ResultPageHeaderOK_Default_img'));
          } else if (res.eventId === "7") {
            setIsPreviousEvent(false);
            setresultimg(t('ResultPage.ResultPageHeaderOK_2025_img'));
          } else if (res.eventId === "8") {
            setIsPreviousEvent(false);
            setresultimg(t('ResultPage.ResultPageHeaderOK_2025_img'));
          }
          else {
            setIsPreviousEvent(true);
            setresultimg(t('ResultPage.ResultPageHeaderExpired_img'));
          }
          setEventID(res.eventId);
        }
        else {
          setresultText(t('ResultPage.NotValidResultText'));
          setresultText2("");
          setresultimg(t('ResultPage.ResultPageHeaderInvalid_img'));
          setIsResultValid(false);
        }
        setIsShowLoading(false);
        setIsResult(true);
        setIsScan(false);
        document.body.style.background = '##DDD4CF';
      } catch (error) {
        /*
        setresultText(t('ResultPage.NotValidResultText'));
        setresultText2("");
        setresultimg(t('ResultPage.ResultPageHeader2img'));
        setIsResultValid(false);
        */
        setresultText3("");
       alert(t('ScanPage.ScanError'));
       MenuBackToHome();
      }
    }).catch(e => {
      /*
      setresultText(t('ResultPage.NotValidResultText'));
      setresultText2("");
      setresultimg(t('ResultPage.ResultPageHeader2img'));
      setIsResult(true);
      setIsShowLoading(false);
      setIsResultValid(false);
      setIsScan(false);
      document.body.style.background = '##DDD4CF';*/
      
      setresultText3("");
      alert(t('ScanPage.ScanError'));
      MenuBackToHome();
    });
  }



  async function stop() {
    try{
      global.config.CameraStart=false;
      qrScanner.stop();
      qrScanner.$canvas.style.display = 'none';
    }
    catch(e){
      
    }
  }

  async function changeCamera() {
    Analytics.record({ name: 'ChangeCamera' });
    stop();
    global.config.isChangeCamera = true;
      const videoInputDevices = await codeReader.listVideoInputDevices();
      if (videoInputDevices && videoInputDevices.length) {

        var backCam = videoInputDevices.filter(d => {
          return d.label && !d.label.includes("front") && !d.label.includes("前置") && !d.label.includes("前置");
        });

        cameraIndex = camindex;
        global.config.cameraId = backCam[cameraIndex].deviceId;
       
        scanCode();
        camName = backCam[cameraIndex].label;
        setCameraInfo( (cameraIndex + 1).toString() + "/" + backCam.length.toString());
        cameraIndex = camindex + 1;
        if (cameraIndex >= backCam.length) {
          cameraIndex = 0;
        }
        setcamindex(cameraIndex);
      }

  }



  function GotoInstructions() {
    document.body.style.overflow = "auto";
    stop();
    isOnScanPage = false;
    setIsScan(false);
    setIsWelcomePage(true);
    var btninstructions = document.getElementById('btninstructions');
    btninstructions.click();
  }
  //scan page End
  ///////////////////////////////////////

  
  ///////////////////////////////////////
  //result page start
  function copyQRCodeToClipboard() {
    const ta = document.createElement("textarea");
    //ta.value = t('ResultPage.QRCodeContent') + "\r\n"+ { resultText3 }.resultText3 + "\r\n"+  t('ResultPage.QRCodeContent2');
    ta.value = { resultText3 }.resultText3 ;
    ta.readOnly = true;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
    alert(t('ResultPage.CopySuccess'));
  };
  function resetScan() {

    stop();
    isOnScanPage = false;
    setIsWelcomePage(true);
    setIsResult(false);
    setresultText2("");
    setresultText3("");
    setIsShowCopyButton(false);
    setIsScan(false);
    document.body.style.background = '#E8D9C4';
    document.body.style.overflow = "auto";
  }

  const [imgscanBorderTopx, setimgscanBorderTopx] = useState('0px');

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  //result page end
  ///////////////////////////////////////
  ///////////////////////////////////////
  //Menu start
  function MenuBackToHome()
  {
    stop();
    setIsResult(false);
    setIsShowLoading(false);
    setIsResultValid(false);
    setIsScan(false);
    setIsWelcomePage(true);
    var btnHome = document.getElementById('btnHome');
    btnHome.click();
    window.scrollTo({
      top: 0
    });
  }
  function MenuScanCode()
  {
    var btnHome = document.getElementById('btnHome');
    btnHome.click();
    setIsResult(false);
    setIsShowLoading(false);
    setIsResultValid(false);
    setIsWelcomePage(false);
    isOnScanPage = false;
    setresultText2("");
    setresultText3("");
    setIsShowCopyButton(false);
    setIsScan(true);
    document.body.style.background = '#E8D9C4';
    document.body.style.overflow = "auto";
    global.config.CameraStart = true;
    setTimeout(() => {
      if (global.config.CameraStart)
        scanCode();
    },1000);
  }
  function MenuHelp()
  {
    stop();
    setIsWelcomePage(false);
    var btninstructions = document.getElementById('btninstructions');
    btninstructions.click();
  }
  function MenuChangeLanguage() {
    if (i18n.languages[0]=='tw' || i18n.languages[0]=='zh-TW')
    {
      i18n.changeLanguage('cn');
    }
    else
    {
      i18n.changeLanguage('tw');
    }
    
    if (isPreviousEvent)
    {
      setresultimg(t('ResultPage.ResultPageHeaderExpired_img'));
    }
    else if (isShowAlert)
    {
      setresultimg(t('ResultPage.ResultPageHeaderIssue_img'));
    }
    else if (isShowCopyButton)
    {
      // if (eventID == "3")
      //   setresultimg(t('ResultPage.ResultPageHeader5img'));
      // else
      //   setresultimg(t('ResultPage.ResultPageHeader1img'));

      if (eventID === "5") {
        setresultimg(t('ResultPage.ResultPageHeaderOK_2024_01_img'));
      } else {
        setresultimg(t('ResultPage.ResultPageHeaderOK_Default_img'));
      }
    }
    else
      setresultimg(t('ResultPage.ResultPageHeaderInvalid_img'));
    document.title = t('WebsiteTitle');
  };
  
  //Menu End
  ///////////////////////////////////////

  ///////////////////////////////////////
  //child call back
  function OpenInstructionPage(){
    
    setIsWelcomePage(false);
  }

  //call back
  ///////////////////////////////////////


  return (
    <div className="App">
      <div className="TopMenuBarMainborder" >
        <div className="TopMenuBarMain" >
          <div className="TopMenuBar">
            <table width="100%">
              <tbody>
                <tr>
                  <td align="left">
                  {!isWelcomePage && (<img onClick={() => MenuBackToHome()} className="TopMenuBarHome" src={imgMenuHome} alt="" />)}
                  </td>
                  <td align="left">
                  {/* {!isProd && (<div className="MenuEnvironment"><b>DEV...2024</b></div>)} */}
                  </td>
                  <td>
                    <div className="TopMenuBarTDRightDiv">
                      <div className="TopMenuBarChangeLangDiv"><img onClick={() => MenuChangeLanguage()} className="TopMenuBarChangeLang" src={t('Menu.langIcon')} alt="" /></div>
                      {useLocation().pathname=="/" && (<div className="TopMenuBarChangeLangDiv"><img onClick={() => MenuHelp()} className="TopMenuBarHelp" src={imgMenuInfo} alt="" /></div>)}
                      {!isScan && isMobile && (<div className="TopMenuBarChangeLangDiv"><img onClick={() => MenuScanCode()} className="TopMenuBarScan" src={imgMenuScan} alt="" /></div>)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="MainContent">
    <Route exact path="/">
        {isWelcomePage && (
          <div className="WelcomePageMain" id="WelcomePageMain">
            <div className="WelcomePageSubMain" id="WelcomePageSubMain">
              <div className="WelcomePageHeader" id="WelcomePageHeader">
                <div className="WelcomePageHeaderBorder">
                  <img className="imgWelcomePageHeader" src={t('WelcomePage.WelcomePageHeaderimg')} alt="" />
                </div>
                <div id="WelcomePageStep1Border1" className="WelcomePageStep1Border1">
                  <img className="imgWelcomePageBG" src={imgWelcomePageBG} alt=""/>
                  
                    <div id="WelcomePageStep1Border2" className="WelcomePageStep1Border2">
                      <img className="imgWelcomePageStep1" src={imgWelcomePageStep1} onClick={() => Step1onClick()} alt="" />
                      <a className="WelcomePageStep1Text">{t('WelcomePage.OrText')}</a>
                      <div id="WelcomePageStep2Border2" className="WelcomePageStep2Border2">
                        <img id="imgWelcomePageStep2" className="imgWelcomePageStep2" src={imgWelcomePageStep2}  onClick={Step2onClick} alt="" />
                      </div>
                      <a className="WelcomePageStep2Text">{t('WelcomePage.OrText')}</a>
                      <div id="WelcomePageStep3Border2" className="WelcomePageStep3Border2">
                        <img id="imgWelcomePageStep3" className="imgWelcomePageStep3" src={imgWelcomePageStep3}  onClick={Step3onClick} alt="" />
                      </div>
                    </div>
                  <div>
                    <img className="imgWelcomePageText1" src={t('WelcomePage.WelcomePageText1img')} alt=""  onClick={() => Step1onClick()} />
                    <img className="imgWelcomePageText2" src={t('WelcomePage.WelcomePageText2img')} alt=""  onClick={Step2imgonClick} />
                    <img className="imgWelcomePageText3" src={t('WelcomePage.WelcomePageText3img')} alt=""  onClick={Step3onClick} /></div>

                </div>
              </div>
            </div>
          </div>
        )}
        {isScan && isMobile && (
          <div>
            <div className="ScanPageMain">
              <div className="ScanPageSubMain">
                <div className="ScanPageHeader2">
                  <div className="ScanPageHeaderTextDiv">
                  <img className="ScanPageHeaderIcon" src={imgWelcomePageStep1} alt="" />
                  <span className="BottomText2">{t('Instructions1.Header')}</span>
                  </div>
                  <div>
                     <div className="ScanPageCameraContent">
                        <center><div className="ScanPageLoading"><Loadingspin /></div></center>
                        {!isIphone && (<div id="qr-code-webcam" width="320" height="240">
                          <video width="100%"  x-webkit-airplay="allow"></video>
                        </div>)}
                        {isIphone && (<div id="qr-code-webcam-iphone" width="320" height="240">
                          <video id="qrcodevideo" width="100%"  x-webkit-airplay="allow" className="QrCodeVideoIpghone"></video>
                          </div>)}
                        {isShowScanBorder &&  (<img id="imgscanBorder" className="imgscanBorder" src={imgscanBorder} alt="" style={{ top: imgscanBorderTopx }}/>)}
                      </div>
                    
                  </div>
                  <div className="BottomText DestopView"><div className="BottomText2">{t('ScanPage.BottomText1')}<br></br>{t('ScanPage.BottomText2')}</div></div>
                  <div className="DivScanPageBottomButton DestopView">
                    <div className="BtnScanPageNeedHelp" onClick={() => GotoInstructions()}>
                      <div className="ScanPagebtncontainer">
                        <div className="ScanPagebtnText">{t('ScanPage.NeedHelp')}</div>
                      </div>
                    </div>
                    <div className="BtnScanPageReturnMainpage" onClick={() => resetScan()}>
                      
                      <div className="ScanPagebtncontainer">
                        <div className="ScanPagebtnText">{t('ScanPage.ReturnMainpage')}</div>
                      </div>
                    </div>
                  </div>
                  {isMultiCam && (<div className="DivChangeCamera DestopView"><div className="DivCameraInfo">{cameraInfo}</div>
                    <img className="BtnChangeCamera" onClick={changeCamera} src={imgChangeCamera} alt="" width="50px" />
                  </div>)}
                </div>
              </div>
            </div>
            
            <div className="BottomText MobileView"><span className="BottomText2">{t('ScanPage.BottomText1')}<br></br>{t('ScanPage.BottomText2')}</span></div>
            <div className="DivScanPageBottomButton MobileView">
              <div className="BtnScanPageNeedHelp" onClick={() => GotoInstructions()}>
                <div className="ScanPagebtncontainer">
                  <div className="ScanPagebtnText">{t('ScanPage.NeedHelp')}</div>
                </div>
              </div>
              <div className="BtnScanPageReturnMainpage" onClick={() => resetScan()}>
                
                <div className="ScanPagebtncontainer">
                  <div className="ScanPagebtnText">{t('ScanPage.ReturnMainpage')}</div>
                </div>
              </div>
            </div>
            {isMultiCam && (<div className="DivChangeCamera MobileView"><div className="DivCameraInfo">{cameraInfo}</div>
              <img className="BtnChangeCamera" onClick={changeCamera} src={imgChangeCamera} alt="" width="50px" />
            </div>)}
          </div>
        )}
        {isScan && !isMobile && (
          <div className="DesktopDiv"><br></br><br></br>
            {t('ScanPage.UserMobileText')}<br></br><br></br><br></br><img src={imgWebsiteQRCode} alt="" />
          </div>
        )}
        {
          isResult && (
            <div className="ResultPageMain">
              <div className="ScanPageSubMain">
                <div className="ScanPageHeader2">
            <div className="ResultPageBG">
              <div className="ResultPageHeaderBorder">
                {isOverScan && isResultValid && !isShowAlert && (<div className="ResultPageHeaderText">{t('ResultPage.ScanResultOverScan')}</div>)}
                <div className="ResultPageResultText"></div>
                <img className="imgWelcomePageHeader" src={resultimg} alt="" />
              </div>
              {isResultValid && !isPreviousEvent && !isShowAlert && (resultText2=="1") && (<div className="ResultPageText1">
              {t('ResultPage.ValidScanFirstTimeResultText')}</div>)}
              {isResultValid && !isPreviousEvent && !isShowAlert && !(resultText2=="1") && (<div className="ResultPageText1">
              {t('ResultPage.ValidScanCountResultText1')}{resultText2}{t('ResultPage.ValidScanCountResultText2')}</div>)}
              {isResultValid && !isPreviousEvent && !isShowAlert && !(resultText2=="1") && (<div className="ResultPageText1">
              {t('ResultPage.ValidScanCountResultText3')}{resultScanTime}{t('ResultPage.ValidScanCountResultText4')}</div>)}
              
              {isShowAlert && !isPreviousEvent && (
                          <div className="ResultPageAlertBorder"><div className="ResultPageAlertText">{t('ResultPage.AlertResultMsg1')}</div></div>
                        )}
              {!isResultValid && (
                          <div className="ResultPageAlertBorder"><div className="ResultPageAlertText">{t('ResultPage.ScanResultFail')}</div></div>
                        )}
              <div className="ResultPagecontact">
                {isShowResultText && (<div className="ResultPagecontactText1">{t('ResultPage.ResultPagecontactText1')}</div>)}
                {isShowResultText && (<div className="ResultPagecontactText1">{t('ResultPage.ResultPagecontactText2')}</div>)}
                <center>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td className="ResultPageTD"><center><a><img className="imgwechat" src={t('ResultPage.wechatimg')} alt="" onClick={toggleModal}/></a></center></td>
                      </tr>
                      <tr>
                        <td className="ResultPageTD"><center><a href={whatsappURLWithQRCode}><img className="imgwhatsapp" src={imgwhatsapp} alt="" /></a></center></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="CSOfficeHourDiv">{t('ResultPage.CSOfficeHourText')}</div>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="ResultQRCode" onClick={copyQRCodeToClipboard}>{t('ResultPage.QRCodeContentText')}<br></br>{resultText3}</div>
                </center>
                <br></br><br></br><br></br>
              </div>
              <div className="ResultPageButton" onClick={resetScan}>
                <div className="ScanPagebtncontainer">
                  <div className="ScanPagebtnText">{t('ScanPage.ReturnMainpage')}</div>
                </div>
              </div>
            </div>
            </div>
            </div>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
              >
                <img src={WeChatQRCode} className="WeChatQRCode" onClick={toggleModal}/>
              </Modal>
            </div>
          )
        }
        {isShowLoading && (<center><Loadingspin /></center>)}
        <Link className="btninstructions" to="/instructions?currenttab=1"><button id="btninstructions" className="btninstructions">說明</button></Link>
        <Link className="btninstructions" to="/instructions?currenttab=2"><button id="btninstructions2" className="btninstructions">說明</button></Link>
        <Link className="btninstructions" to="/instructions?currenttab=3"><button id="btninstructions3" className="btninstructions">說明</button></Link>
        <Link className="btninstructions" to="/"><button id="btnHome" className="btninstructions">Home</button></Link>
      </Route>
      <Route path="/instructions"  render={
          props => <Instructions {...props} MenuScanCode={MenuScanCode} MenuBackToHome={MenuBackToHome} OpenInstructionPage={OpenInstructionPage} />
        }/>
      </div>

    </div>
  );
}

export default App;
