import "./instructions.css";
import React from 'react'
import imgwhatsapp from './img/whatsapp.png';
import WeChatQRCode from "./img/WeChatQRCode.jpg";
import { withTranslation } from 'react-i18next';
import './i18n';
import Modal from "react-modal";
var whatsappURL = "https://wa.me/85291628866?text=";


Modal.setAppElement("#root");

class ContactCS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggleModal = () => {
    var isOpen=false;
    console.log(this.state);
    if (!this.state.isOpen)
      isOpen=true;
    this.setState({isOpen: isOpen});
  }

  copyQRCodeToClipboard(InputText1, InputText2) {
    const ta = document.createElement("textarea");
    ta.innerText = InputText1;//t('ResultPage.QRCodeContent') ;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
    alert(InputText2);
  };
  render() {
    const { t } = this.props;
    return (
      <div className="InstructionText">
        <center>
          <table>
            <tbody>
              <tr>
                <td width='30%'>
                <center><a ><img className="imgwechat" src={t('ResultPage.wechatimg')} alt="" onClick={this.toggleModal}/></a></center><br></br>
                </td>
              </tr>
              <tr>
                <td><center>
                {this.props.tabindex==2 && (<a href={whatsappURL + t('ResultPage.QRCodeContent2')}><img className="imgwhatsapp" src={imgwhatsapp} alt="" /></a>)}
                {this.props.tabindex==3 && (<a href={whatsappURL + t('ResultPage.QRCodeContent3')}><img className="imgwhatsapp" src={imgwhatsapp} alt="" /></a>)}
                <br></br><br></br></center>
                </td>
              </tr>
              <tr>
                <td colSpan='2'><center>
                  <div className="CSOfficeHourDiv">{t('ResultPage.CSOfficeHourText')}</div></center></td>
              </tr>
            </tbody>
          </table>
        </center>
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={this.toggleModal}
          contentLabel="My dialog"
          className="mymodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <img src={WeChatQRCode} className="WeChatQRCode" onClick={this.toggleModal} alt=""/>
        </Modal>
      </div>
    )
  }
}
export default withTranslation()(ContactCS);